import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { HeroContainer, HeroHeading } from '../components/HeroHeading'

const StyledCopy = styled.div`
  margin: 50px 20%;
  line-height: 30px;

  @media only screen and (max-width: 45em) {
    margin: 50px 10%;
  }
`

class LoudMouthPage extends Component {
  render() {
    const { data } = this.props
    const loudmouthImage = data.loudmouthImage
    const suckazImage = data.suckazImage

    return (
      <>
        <HeroContainer>
          <Image
            sizes={{
              ...loudmouthImage.image.fluid,
              base64: loudmouthImage.image.sqip.dataURI,
            }}
          />
          <HeroHeading>
            <h1>{loudmouthImage.heading}</h1>
            <p>{loudmouthImage.subHeading}</p>
          </HeroHeading>
        </HeroContainer>
        <StyledCopy>
          {data.copy.edges.map(({ node }) => (
            <div key={node.id}>
              <p>{node.text.text}</p>
            </div>
          ))}
        </StyledCopy>
        <HeroContainer>
          <Image
            sizes={{
              ...suckazImage.image.fluid,
              base64: suckazImage.image.sqip.dataURI,
            }}
          />
          <HeroHeading textPosition="bottom-left">
            <h1
              dangerouslySetInnerHTML={{
                __html: suckazImage.markdownHeading.childMarkdownRemark.html,
              }}
            />
          </HeroHeading>
        </HeroContainer>
      </>
    )
  }
}
LoudMouthPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default LoudMouthPage

export const pageQuery = graphql`
  query pageQuery {
    copy: allContentfulModuleParallaxCopy {
      edges {
        node {
          id
          title
          text {
            id
            text
          }
        }
      }
    }
    loudmouthImage: contentfulHeroImage(slug: { eq: "i-am-loud-mouth" }) {
      heading
      subHeading
      image {
        ...BigSqipPreview
      }
    }
    suckazImage: contentfulHeroImage(
      slug: { eq: "i-run-dem-suckaz-recordings" }
    ) {
      markdownHeading {
        childMarkdownRemark {
          html
        }
      }
      image {
        ...BigSqipPreview
      }
    }
  }
`
