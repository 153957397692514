import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const HeroHeading = styled.div`
  position: absolute;
  z-index: 66;
  text-align: center;
  color: white;
  ${(props) =>
    props.textPosition === 'bottom-left'
      ? css`
          bottom: 10%;
          left: 10%;
        `
      : css`
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `};
`
HeroHeading.propTypes = {
  textPosition: PropTypes.string,
}

export const HeroContainer = styled.div`
  position: relative;
`
